import React from 'react'
import { XIcon } from "@heroicons/react/outline"

const NavCloseIcon = ({ setOpenMobileMenu }) => {
  return (
    <div className="ml-3 h-7 flex items-center">
      <button
        type="button"
        className="bg-[white] rounded-md text-gray-400 hover:text-gray-500"
        onClick={() => setOpenMobileMenu(false)}
      >
        <span className="sr-only">Close panel</span>
        <XIcon className="h-10 w-10" aria-hidden="true" />
      </button>
    </div>
  )
}

export default NavCloseIcon
