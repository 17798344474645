import React from 'react'

const NavCTA = () => {
    return (
      <p className="mb-[3em] text-center text-base font-medium text-gray-500">
        <button className="btnPrimarySmall text-[1.6em] w-full py-6">
          Register
        </button>
      </p>
    )
}

export default NavCTA
