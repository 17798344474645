import React from 'react'
import { Popover } from "@headlessui/react"
import LangSelector from '../header-components/LangSelector'

const HeaderLangSelector = ({ languageSelector, isVisible }) => {
  return (
    <Popover.Group className="hidden md:flex space-x-10">
      {languageSelector.map(lang => (
        <LangSelector data={lang} isVisible={isVisible} />
      ))}
    </Popover.Group>
  )
}

export default HeaderLangSelector
