import React from 'react'

const FooterCTA = () => {
    return (
      <div className="w-vw bg-primaryColor ">
        <div className="container">
          <div className="py-[30px] px-0 text-[#fff] flex flex-col lg:flex-row items-center justify-between">
            <h2 className="text-[#fff] mb-0">Get Started</h2>
            <div className="lg:border-l-[0.5rem] rounded-lg">
              <div className="lg:ml-[2em] py-[3em] lg:py-0">
                <p className="text-center text-[#fff] lg:text-left">
                  Experience a new level of trading with the right support when
                  you need it.
                </p>
                <p className="text-center text-[#fff] lg:text-left">
                  Sign up for a free account and trade smart with CAPEX.com
                </p>
              </div>
            </div>
            <a className="btnSecondaryMediumOutline bg-[#fff] border-0 hover:bg-[#f2f2f2] hover:opacity-100 hover:text-[#0995dd]">
              Create Account
            </a>
          </div>
        </div>
      </div>
    )
}

export default FooterCTA
