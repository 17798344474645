import React, {useState} from 'react'
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"
import LicenceSelector from '../../../header-sections/header-components/LicenceSelector'
const FooterLicenceSelector = () => {
    
  const [open, setOpen] = useState(false)
    return (
      <button onClick={() => setOpen(true)} className="bg-[#fff] rounded-lg">
        <p className="text-[1.6em] py-[15px] px-[20px] flex">
          Entity:{" "}
          <span className="blackLink font-medium text-[16px] ml-[.7em] cursor-pointer flex items-center">
            CySEC
            <i>
              <IoMdArrowDropdown className="-rotate-90 h-7 w-7 mt-1  transition delay-0 ease-in-out group-hover:text-secondaryColor" />
            </i>
          </span>
        </p>
        <LicenceSelector open={open} setOpen={setOpen} />
      </button>
    )
}

export default FooterLicenceSelector
