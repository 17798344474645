import React from 'react'
import FooterMenuItems from './footer-components/footerMenu/FooterMenuItems'
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

const menu = [
  {
    name: "Markets",
    useHover: true,
    link: {
      enabled: true,
      href: "https://github.com/",
      newTab: true,
    },
    subLinks: [
      {
        name: "Forex",
        href: "#",
      },
      {
        name: "Indices",
        href: "#",
      },
      {
        name: "Bonds",
        href: "#",
      },
      {
        name: "ETFs",
        href: "#",
      },
      {
        name: "Commodities",
        href: "#",
      },
      {
        name: "Shares",
        href: "#",
      },
      {
        name: "Blends",
        href: "#",
      },
      {
        name: "Cryptocurrencies",
        href: "#",
      },
    ],
  },
  {
    name: "Learn To Trade",
    useHover: true,
    link: {
      enabled: true,
      href: "https://github.com/",
      newTab: false,
    },
    subLinks: [
      {
        name: "CAPEX Academy",
        href: "#",
      },
      {
        name: "IPO",
        href: "#",
      },
      {
        name: "Market Overview",
        href: "#",
      },
      {
        name: "Webinars",
        href: "#",
      },
      {
        name: "Financial Dictionary",
        href: "#",
      },
      {
        name: "Featured Articles",
        href: "#",
      },
    ],
  },
  {
    name: "Tools",
    useHover: true,
    subLinks: [
      {
        name: "Trading Platforms",
        href: "#",
        subItems: [
          {
            name: "CAPEX WebTrader",
            href: "#",
          },
          {
            name: "MT5",
            href: "#",
          },
        ],
      },
      {
        name: "Integrated Tools",
        href: "#",
      },
      {
        name: "Trading Central",
        href: "#",
      },
    ],
  },
  {
    name: "Resources",
    useHover: true,
    subLinks: [
      {
        name: "Market News",
        href: "#",
      },
      {
        name: "Economic Calendar",
        href: "#",
      },
      {
        name: "Weekly Expiration Dates",
        href: "#",
      },
      {
        name: "Bank Holidays",
        href: "#",
      },
      {
        name: "CFD expiration dates",
        href: "#",
      },
      {
        name: "Trading conditions",
        href: "#",
      },
      {
        name: "Account types",
        href: "#",
      },
    ],
  },
  {
    name: "Company",
    useHover: true,
    subLinks: [
      {
        name: "About Us",
        href: "#",
      },
      {
        name: "News Room",
        href: "#",
      },
      {
        name: "Awards",
        href: "#",
      },
      {
        name: "Licences & Regulations",
        href: "#",
      },
      {
        name: "Careers",
        href: "#",
      },
      {
        name: "Contact Us",
        href: "#",
      },
    ],
  },
  {
    name: "Become A Partner",
    useHover: true,
    subLinks: [
      {
        name: "Partnership Programs",
        href: "#",
        subItems: [
          {
            name: "CAPEX Partners",
            href: "#",
          },
          {
            name: "CAPEX Affiliates",
            href: "#",
          },
        ],
      },
    ],
  },
]


const FooterMenu = () => {
    return (
      <div className="container">
        <div className="hidden lg:grid grid-cols-3 lg:grid-cols-6 gap-3 mb-[6em]">
          {menu.map(item => {
            return (
              <div className="flex rounded-2xl flex-col justify-between">
                <div>
                  <h2 className="font-medium text-[16px] mb-[2em]">
                    {item.name}
                  </h2>
                  <ul>
                    <li>
                      {item.subLinks.map((item, index) => (
                        <a
                          key={index}
                          href={item.href}
                          className="-m-3 p-3 flex items-start rounded-lg blackLink font-[16px] z-[99]"
                        >
                          <div className="">
                            <p className="blackLink text-[16px] text-[#737373] mb-[0.5em]">
                              {item.name}
                              {item.subItems
                                ? item.subItems.map(item => (
                                    <span
                                      as="a"
                                      key={item.name}
                                      href={item.href}
                                      className="-m-3 p-3 pl-[0] first:pt-[20px] flex items-start rounded-lg blackLink group text-[16px] text-[#737373]"
                                    >
                                      <IoMdArrowDropdown
                                        className="mt-2 h-7 w-7 -rotate-90  transition delay-0  ease-in-out fill-[#737373] group-hover:fill-[#0995dd]"
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </span>
                                  ))
                                : null}
                            </p>
                          </div>
                        </a>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            )
          })}
        </div>
        <div className="w-full px-0 py-[1rem] container onmobile">
          <div className="w-full mx-auto bg-white">
            {menu.map(menuItem => (
              <FooterMenuItems data={menuItem} />
            ))}
          </div>
        </div>
      </div>
    )
}

export default FooterMenu
