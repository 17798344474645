/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useRef } from "react"
import { Fragment } from "react"
import { Popover, Transition, Dialog } from "@headlessui/react"

import useElementOnScreen from "./ObserverHook"
import DesktopSlide from "./homepage-sections/homepage-components/desktop-slide"
import MobileSlide from "./homepage-sections/homepage-components/mobile-slide"
import HeaderLogo from "./header-sections//desktopSections/HeaderLogo"
import HeaderMobileNavIcon from "./header-sections/desktopSections/HeaderMobileNavIcon"
import HeaderLicenceSelector from "./header-sections//desktopSections/HeaderLicenceSelector"
import HeaderMenu from "./header-sections/desktopSections/HeaderMenu"
import HeaderLangSelector from "./header-sections/desktopSections/HeaderLangSelector"
import HeaderLogIn from "./header-sections/desktopSections/HeaderLogIn"
import HeaderCTA from "./header-sections/desktopSections/HeaderCTA"
import NavLogo from "./header-sections/mobileSections/NavLogo"
import NavCloseIcon from "./header-sections/mobileSections/NavCloseIcon"
import NavMenu from "./header-sections/mobileSections/NavMenu"
import NavLogIn from "./header-sections/mobileSections/NavLogIn"
import NavCTA from "./header-sections/mobileSections/NavCTA"
import NavLicenceSelector from "./header-sections/mobileSections/NavLicenceSelector"

const menu = [
  {
    name: "Markets",
    useHover: true,
    link: {
      enabled: true,
      href: "https://github.com/",
      newTab: true,
    },
    subLinks: [
      {
        name: "Forex",
        href: "#",
      },
      {
        name: "Indices",
        href: "#",
      },
      {
        name: "Bonds",
        href: "#",
      },
      {
        name: "ETFs",
        href: "#",
      },
      {
        name: "Commodities",
        href: "#",
      },
      {
        name: "Shares",
        href: "#",
      },
      {
        name: "Blends",
        href: "#",
      },
      {
        name: "Cryptocurrencies",
        href: "#",
      },
    ],
  },
  {
    name: "Learn To Trade",
    useHover: true,
    link: {
      enabled: true,
      href: "https://github.com/",
      newTab: false,
    },
    subLinks: [
      {
        name: "CAPEX Academy",
        href: "#",
      },
      {
        name: "IPO",
        href: "#",
      },
      {
        name: "Market Overview",
        href: "#",
      },
      {
        name: "Webinars",
        href: "#",
      },
      {
        name: "Financial Dictionary",
        href: "#",
      },
      {
        name: "Featured Articles",
        href: "#",
      },
    ],
  },
  {
    name: "Tools",
    useHover: true,
    subLinks: [
      {
        name: "Trading Platforms",
        href: "#",
        subItems: [
          {
            name: "CAPEX WebTrader",
            href: "#",
          },
          {
            name: "MT5",
            href: "#",
          },
        ],
      },
      {
        name: "Integrated Tools",
        href: "#",
      },
      {
        name: "Trading Central",
        href: "#",
      },
    ],
  },
  {
    name: "Resources",
    useHover: true,
    subLinks: [
      {
        name: "Market News",
        href: "#",
      },
      {
        name: "Economic Calendar",
        href: "#",
      },
      {
        name: "Weekly Expiration Dates",
        href: "#",
      },
      {
        name: "Bank Holidays",
        href: "#",
      },
      {
        name: "CFD expiration dates",
        href: "#",
      },
      {
        name: "Trading conditions",
        href: "#",
      },
      {
        name: "Account types",
        href: "#",
      },
    ],
  },
  {
    name: "Company",
    useHover: true,
    subLinks: [
      {
        name: "About Us",
        href: "#",
      },
      {
        name: "News Room",
        href: "#",
      },
      {
        name: "Awards",
        href: "#",
      },
      {
        name: "Licences & Regulations",
        href: "#",
      },
      {
        name: "Careers",
        href: "#",
      },
      {
        name: "Contact Us",
        href: "#",
      },
    ],
  },
  {
    name: "Become A Partner",
    useHover: true,
    subLinks: [
      {
        name: "Partnership Programs",
        href: "#",
        subItems: [
          {
            name: "CAPEX Partners",
            href: "#",
          },
          {
            name: "CAPEX Affiliates",
            href: "#",
          },
        ],
      },
    ],
  },
]

const languageSelector = [
  {
    name: "Lang",
    flag: "https://capex.com/capex/images/flags/en.svg",
    subLangs: [
      {
        name: "English",
        flag: "https://capex.com/capex/images/flags/en.svg",
        href: "#",
        active: true,
      },
      {
        name: "اللغة العربية",
        flag: "https://capex.com/capex/images/flags/ar.svg",
        href: "#",
        active: false,
      },
      {
        name: "Română",
        flag: "https://capex.com/capex/images/flags/ro.svg",
        href: "#",
        active: false,
      },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Header() {
  const targetRef = useRef(null)
  const isVisible = useElementOnScreen(
    {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    },
    targetRef
  )
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [openMobile, setopenMobile] = useState(false)
  return (
    <>
      <Popover className="relative sm:absolute top-0 bg-[transparent] w-full z-[99] flex items-center justify-center">
        <div
          className={classNames(
            isVisible
              ? "sm:static  md:bg-[transparent]"
              : "sm:fixed top-0 md:bg-primaryColor transform translate-y-[0] transition-all duration-500 ease-in shadow-bottom",
            openMobileMenu
              ? "transform translate-y-[-101%] transition-all duration-200 ease-in"
              : "transform translate-y-[0] transition-all duration-500 ease-in",
            "fixed top-0 sm:static flex w-full bg-[#fff] mx-auto justify-between items-center py-0 sm:px-6 md:justify-start md:space-x-10"
          )}
        >
          <div className="flex justify-between items-center sm:py-6 sm:px-6 lg:justify-start md:space-x-10 w-full lg:w-auto">
            <div className="flex justify-between items-center w-full px-4 lg:px-8 py-8 sm:py-0 sm:px-0">
              <HeaderLogo isVisible={isVisible} />
              <HeaderMobileNavIcon
                isVisible={isVisible}
                openMobileMenu={openMobileMenu}
                setOpenMobileMenu={setOpenMobileMenu}
              />
            </div>
          </div>
          <div className="hidden lg:flex-1 lg:flex lg:items-center justify-between 2xl:justify-around">
            <HeaderMenu menu={menu} isVisible={isVisible} />
            <div className="flex items-center md:ml-12">
              <HeaderLicenceSelector isVisible={isVisible} />
              <HeaderLangSelector
                languageSelector={languageSelector}
                isVisible={isVisible}
              />
              <HeaderLogIn isVisible={isVisible} />
              <HeaderCTA isVisible={isVisible} />
            </div>
          </div>
        </div>

        {/* MOBILE MENU */}
        <Transition.Root show={openMobileMenu} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 overflow-hidden z-10"
            onClose={setOpenMobileMenu}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="absolute inset-0 bg-[#000] bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex z-[99]">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full flex flex-col py-6 pt-10 bg-[#fff] shadow-xl overflow-y-scroll">
                      <div className="px-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900 mb-0">
                            <NavLogo />
                          </Dialog.Title>
                          <NavCloseIcon setOpenMobileMenu={setOpenMobileMenu} />
                        </div>
                      </div>
                      <div className="mt-6 relative flex-1 px-4 sm:px-6">
                        {/* Replace with your content */}
                        <NavMenu menu={menu} />
                        <div className="py-6 px-8">
                          <div className="mt-6">
                            <NavCTA />
                            <NavLogIn />
                          </div>
                        </div>
                        <NavLicenceSelector
                          openMobile={openMobile}
                          setopenMobile={setopenMobile}
                        />
                        {/* /End replace */}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </Popover>
      <div ref={targetRef} className="w-full">
        <DesktopSlide />
        <MobileSlide />
      </div>
    </>
  )
}
