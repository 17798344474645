import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
const FooterPayments = () => {
    return (
      <div className="container border-b border-[#dfdfe7]">
        <div className="max-w-full mx-auto py-12 sm:px-6 md:px-8">
          <div className="grid grid-cols-2 gap-8  md:grid-cols-6">
            <div className="col-span-1 flex justify-center md:col-span-1 h-12">
              <StaticImage
                src="../../images/visa.svg"
                quality={95}
                width={50}
                height={32}
                objectFit="contain"
                formats={["auto", "webp", "avif"]}
                alt=""
                placeholder="blurred"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-1 h-12">
              <StaticImage
                src="../../images/mastercard.svg"
                quality={95}
                width={148}
                height={28}
                objectFit="contain"
                formats={["auto", "webp", "avif"]}
                alt=""
                placeholder="blurred"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-1 h-12">
              <StaticImage
                src="../../images/visa-electron.svg"
                quality={95}
                width={47}
                height={30}
                objectFit="contain"
                formats={["auto", "webp", "avif"]}
                alt=""
                placeholder="blurred"
              />
            </div>
            <div className="col-span-1 flex justify-center  md:col-span-1 h-12">
              <StaticImage
                src="../../images/maestro.svg"
                quality={95}
                width={124}
                height={28}
                objectFit="contain"
                formats={["auto", "webp", "avif"]}
                alt=""
                placeholder="blurred"
              />
            </div>
            <div className="col-span-1 flex justify-center  md:col-span-1 h-12">
              <StaticImage
                src="../../images/neteller.svg"
                height={16}
                width={95}
                objectFit="contain"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
                placeholder="blurred"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-1 h-12">
              <StaticImage
                src="../../images/skrill.svg"
                height={20}
                width={58}
                quality={95}
                objectFit="contain"
                formats={["auto", "webp", "avif"]}
                alt=""
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </div>
    )
}

export default FooterPayments
