import React from "react"
import FooterLangSelector from "./footer-components/footerEntities/FooterLangSelector"
import FooterLicenceSelector from "./footer-components/footerEntities/FooterLicenceSelector"

const FooterEntities = () => {
  return (
    <div className="hidden lg:flex items-center">
      <FooterLicenceSelector />
      <FooterLangSelector />
    </div>
  )
}

export default FooterEntities
