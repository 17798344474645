import React, {useState} from 'react'
import LicenceSelector from '../header-components/LicenceSelector'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const HeaderLicenceSelector = ({ isVisible }) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <p
        className={classNames(
          isVisible ? "whiteOffsetLink" : "whiteLink",
          "  font-normal text-[14px] 2xl:text-[18px] mr-[1em] cursor-pointer"
        )}
        onClick={() => setOpen(true)}
      >
        CySEC
      </p>
      <LicenceSelector open={open} setOpen={setOpen} />
    </div>
  )
}

export default HeaderLicenceSelector
