import React from "react"
import { Popover } from "@headlessui/react"
import LangSelector from "./components/LangSelector"
const languageSelector = [
  {
    name: "Lang",
    flag: "https://capex.com/capex/images/flags/en.svg",
    subLangs: [
      {
        name: "English",
        flag: "https://capex.com/capex/images/flags/en.svg",
        href: "#",
        active: true,
      },
      {
        name: "اللغة العربية",
        flag: "https://capex.com/capex/images/flags/ar.svg",
        href: "#",
        active: false,
      },
      {
        name: "Română",
        flag: "https://capex.com/capex/images/flags/ro.svg",
        href: "#",
        active: false,
      },
    ],
  },
]

const FooterLangSelector = () => {
  return (
    <div className="bg-[#fff] rounded-lg ml-[2em] flex items-center">
      <p className="text-[1.6em] py-[15px] px-[20px]">Language:</p>
      <Popover.Group className="hidden md:flex space-x-10 ">
        {languageSelector.map(lang => (
          <LangSelector data={lang} />
        ))}
      </Popover.Group>
    </div>
  )
}

export default FooterLangSelector
