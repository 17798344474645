import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const FooterLogo = () => {
    return (
      <div className="container lg:border-b border-[#dfdfe7] mb-[5em]">
        <div className="flex flex-col items-center justify-between pt-[50px] md:flex-row">
          <div className="flex w-full items-center justify-between mb-[2em] md:justify-start">
            <StaticImage
              className="h-full flex items-center w-[13rem]"
              src="../../images/CAPEX_Red.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Capex.com"
              placeholder="blurred"
            />
            <p className="ml-[1em] text-[14px] whitespace-nowrap mb-[0.8em]">
              © 2021 ae.capex.com
            </p>
          </div>
          <div className="flex items-center justify-between min-w-[250px] mb-[2em]">
            <StaticImage
              src="../../images/google_play.png"
              width={120}
              height={39}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="GooglePlay"
              placeholder="blurred"
            />
            <StaticImage
              src="../../images/app_store.png"
              width={120}
              height={39}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="AppStore"
              placeholder="blurred"
            />
          </div>
        </div>
      </div>
    )
}

export default FooterLogo
