import * as React from "react"
import { Popover, Transition } from "@headlessui/react"
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const MenuItemsMobile = ({ data }) => {
  const { name, subLinks } = data

  return (
    <div className="w-[full] px-0">
      <div className="w-full mx-auto bg-white rounded-2xl">
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button className="flex justify-between py-[2em] w-full text-left border-b border-[#e2e2e2]">
                <span className=" text-[15px] ml-[1em]">{name}</span>
                <IoMdArrowDropdown
                  className={classNames(
                    open ? "rotate-180" : "",
                    "ml-2 mr-[1em] mt-2 h-7 w-7  transition delay-0  ease-in-out fill-[#000] group-hover:fill-[#000]"
                  )}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                show={open}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="pt-4 pb-[2em] text-sm text-gray-500 ml-[2em]">
                  {subLinks.map((item, index) => (
                    <a
                      key={index}
                      href={item.href}
                      className="-m-3 p-3 flex items-start rounded-lg blackLink font-[16px] z-[99]"
                    >
                      <div>
                        <p className="blackLink text-[14px]">
                          {item.name}
                          {item.subItems
                            ? item.subItems.map(item => (
                                <span
                                  as="a"
                                  key={item.name}
                                  href={item.href}
                                  className="-m-3 p-3 pl-[0] first:pt-[20px] flex items-start rounded-lg blackLink group text-[16px]"
                                >
                                  <IoMdArrowDropdown
                                    className="mt-2 h-7 w-7 -rotate-90  transition delay-0  ease-in-out fill-[#000] group-hover:fill-[#000]"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </span>
                              ))
                            : null}
                        </p>
                      </div>
                    </a>
                  ))}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  )
}

export default MenuItemsMobile
