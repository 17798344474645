import React, { useState } from "react"
import "../../../styles/styles.css"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}


const Progress = ({ done, index, activeIndex, isHovered }) => {

  const [style, setStyle] = useState({})
  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      transition: "width 5s linear",
      width: `${index === activeIndex ? done : 0}%`,
    }
    setStyle(newStyle)
  }, 0)

  return (
    <div className={classNames( index === activeIndex ? "opacity-100" : "opacity-0", "progress")}>
      <div
        className="progressDone"
        style={isHovered ? { width: "0" } : index === activeIndex ? style : { width: "0" }}
      ></div>
    </div>
  )
}

export default Progress
