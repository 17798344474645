import React from 'react'
import { Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { IoMdArrowDropdown } from '@react-icons/all-files/io/IoMdArrowDropdown'

const licences = [
  {
    name: "FSCA",
    button: "#",
    subList: [
      {
        text: "za.capex.com, operated by JME Financial Services (Pty) Ltd Regulated by Financial Sector Conduct Authority (FSCA) under the License no. 37166",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:300",
      },
    ],
  },
  {
    name: "CySEC",
    button: "#",
    subList: [
      {
        text: "CAPEX.com, operated by Key Way Investments Limited Regulated by CySEC under the License no. 292/16",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:30 for retail clients",
      },
      {
        text: "ICF Investor Compensation up to EUR 20,000",
      },
    ],
  },
  {
    name: "ADGM (FSRA)",
    button: "#",
    subList: [
      {
        text: "ae.capex.com, operated by Key Way Markets Ltd, Regulated by ADGM Financial Services Regulatory Authority under the License no. 190005",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:30",
      },
      {
        text: "For Retail clients, Professional clients and Market Counterparties",
      },
    ],
  },
  {
    name: "FSA",
    button: "#",
    subList: [
      {
        text: "sc.capex.com, operated by KW Investments Limited, Regulated by the Seychelles Financial Services Authority, under the License no. SD020",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:300",
      },
    ],
  },
]

export default function LicenceSelector({open, setOpen}) {


  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[999] inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#000] bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[80%] 2xl:max-w-[60%] sm:w-full bg-[#fff]">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="grid grid-cols-4 gap-3 text-center">
                  {licences.map(licence => {
                    return (
                      <div className="flex rounded-2xl flex-col justify-between hover:shadow-2xl transition delay-200 ease-in-out py-[2em]">
                        <div>
                          <h2 className="mb-[0.5em] text-[3em]">
                            {licence.name}
                          </h2>
                          <ul>
                            {licence.subList.map((item, index) => {
                              return (
                                <li key={index} className=" py-[0.625em] px-[1.3em] bg-[#f7f7f7] even:bg-[#f0f0f0]">
                                  <p className="text-[greyColor] text-[1.4em]">
                                    {item.text}
                                  </p>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                        <div className="flex justify-center">
                          <a
                            className="btnPrimarySmall text-[1.6em] mt-[1em] flex items-center text-center justify-center"
                            type="submit"
                            href={licence.button}
                            onClick={() => setOpen(false)}
                          >
                            <span>Continue</span>
                            <IoMdArrowDropdown className="-rotate-90 mr-[2em] h-7 w-7 mt-1  transition delay-0 ease-in-out fill-[#fff]" />
                          </a>
                        </div>
                      </div>
                    )
                  })}
                  <div></div>
                </div>
                <div className="flex justify-center flex-col">
                  <p className="text-[1.4em] text-center w-[90%] my-0 mx-auto mt-[3em]">
                    The information on the website is displayed in accordance
                    with the regulation you have chosen. If you would like to
                    switch to a different regulation, you can select another one
                    from the menu and the website will adjust accordingly.
                  </p>
                  <a href="#" className="blueLink text-[1.4em] text-center w-[90%] my-0 mx-auto mt-[1em] mb-[2em]">
                    For more information regarding the trading conditions,
                    please click here.
                  </a>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
