import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const FooterSocials = () => {
    return (
      <div className="flex items-center ">
        <p className="text-[1.6em] mr-[1em] ondesktop">Follow us:</p>
        <div className="flex items-center w-[96vw] px-[2em] lg:px-0 lg:w-[25em] justify-around lg:justify-between">
          <div className="text-center">
            <StaticImage
              src="../../images/fb_logo.png"
              height={30}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/istg_logo.png"
              height={30}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/linkedIn_logo.png"
              height={30}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/twitter_logo.png"
              height={30}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <div className="text-center h-">
            <StaticImage
              src="../../images/yt_logo.png"
              height={30}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
        </div>
      </div>
    )
}

export default FooterSocials
