import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const NavLogo = () => {
    return (
      <StaticImage
        className="h-full flex items-center w-[13rem]"
        src="../../../images/CAPEX_red.svg"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Capex.com"
        placeholder="blurred"
      />
    )
}

export default NavLogo
