import * as React from "react"
import FooterDisclaimer from "./footer-sections/FooterDisclaimer"
import FooterPayments from "./footer-sections/FooterPayments"
import FooterPress from "./footer-sections/FooterPress"
import FooterSocials from "./footer-sections/FooterSocials"
import FooterCTA from "./footer-sections/FooterCTA"
import FooterLogo from "./footer-sections/FooterLogo"
import FooterMenu from "./footer-sections/FooterMenu"
import FooterEntities from "./footer-sections/FooterEntities"

const Footer = () => {
  return (
    <footer>
      <FooterCTA />
      <FooterLogo />
      <FooterMenu />
      <FooterPress />
      <div className="mx-[1.5em] lg:mx-0 w-vw bg-[#f6f6f6]">
        <div className="container py-[3em] lg:py-[5em]">
          <div className="flex items-center justify-center lg:justify-between">
            <FooterSocials />
            <FooterEntities />
          </div>
        </div>
      </div>
      <FooterPayments />
      <FooterDisclaimer />
    </footer>
  )
}

export default Footer
