import React from 'react'

const NavLogIn = () => {
    return (
      <p className="text-center text-base font-medium text-gray-500">
        <button className="btnSecondarySmall text-[1.6em] w-full py-6">
          Login
        </button>
      </p>
    )
}

export default NavLogIn
