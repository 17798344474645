import React, { useState } from "react"
import "../../../styles/styles.css"


const ProgressMobile = ({ done, index, activeIndex }) => {
  const [style, setStyle] = useState({})
  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      transition: "width 5s linear",
      width: `${index === activeIndex ? done : 0}%`,
    }
    setStyle(newStyle)
  }, 0)

  return (
    <div
      className="progressMobile"
    >
      <div
        className="progressDoneMobile"
        style={
            index === activeIndex
            ? style
            : { width: "0" }
        }
      ></div>
    </div>
  )
}

export default ProgressMobile
