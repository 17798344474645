import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
const FooterPress = () => {
    return (
      <div className="container mb-[4em] ondesktop">
        <div className="flex items-center justify-center mb-[45px]">
          <div className="border-b border-[#dfdfe7] w-full"></div>
          <div>
            <p className="text-[16px] font-medium px-[35px] whitespace-nowrap ">
              We are in the press
            </p>
          </div>
          <div className="border-b border-[#dfdfe7] w-full"></div>
        </div>
        <div className="text-center">
          <StaticImage
            src="../../images/press-images.png"
            width={950}
            height={37}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            placeholder="blurred"
          />
        </div>
      </div>
    )
}

export default FooterPress
