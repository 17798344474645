import React from 'react'
import LicenceSelectorMobile from '../header-components/LicenceSelectorMobile'

const NavLicenceSelector = ({ openMobile, setopenMobile }) => {
  return (
    <div className="px-6 mb-8">
      <p className="py-6 border-t border-b border-[#e2e2e2] text-[14px] text-[#000] flex items-center justify-between w-full">
        Regulator{" "}
        <span
          onClick={() => setopenMobile(true)}
          className="blueLink text-[14px]"
        >
          <LicenceSelectorMobile
            openMobile={openMobile}
            setopenMobile={setopenMobile}
          />
          CySEC
        </span>
        <span>ADGM</span>
        <span>FSCA</span>
        <span>FSA</span>
      </p>
    </div>
  )
}

export default NavLicenceSelector
