import React from 'react'
import { HiMenuAlt2 } from "@react-icons/all-files/hi/HiMenuAlt2"
import { Popover } from "@headlessui/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const HeaderMobileNavIcon = ({isVisible, openMobileMenu, setOpenMobileMenu }) => {
  return (
    <div
      className="-mr-2 -my-2 lg:hidden"
      onClick={() => setOpenMobileMenu(!openMobileMenu)}
    >
      <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100">
        <span className="sr-only">Open menu</span>
        <HiMenuAlt2
          className={classNames(
            isVisible
              ? "text-primaryColor"
              : "text-primaryColor md:text-[#fff]",
            "h-9 w-9 "
          )}
          aria-hidden="true"
        />
      </Popover.Button>
    </div>
  )
}

export default HeaderMobileNavIcon
