import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const HeaderCTA = ({ isVisible }) => {
  return (
    <button
      className={classNames(
        isVisible ? "btnPrimarySmall" : "btnWhiteSmall",
        "btnPrimarySmall text-[1.4em] transition delay-0 ease-in-out"
      )}
    >
      Register
    </button>
  )
}

export default HeaderCTA
