import React from 'react'

const FooterDisclaimer = () => {
    return (
      <div className="container">
        <div className="py-[45px]">
          <p className="text-[16px] text-[#737373]">
            <span className="font-medium text-[#000]">Risk Warning:</span>{" "}
            Trading leveraged products is highly speculative, involves
            significant risk of loss and is not suitable for all investors.
            Before trading, you are strongly advised to read and ensure that you
            understand the relevant Risk Disclosure and warnings{" "}
            <a href="#" className="text-[16px]">
              here
            </a>
            . There is a substantial risk that you may lose all of your initial
            investment. We advise you to consider whether trading leveraged
            products is appropriate for you in light of your own personal
            circumstances. We recommend that you ensure you fully understand all
            risks involved before trading. 24.04% of retail investor accounts
            generate profits when trading leveraged products with this provider.
            <span className="font-medium text-[#000]">
              {" "}
              <br></br>
              <br></br>
              Restricted Jurisdictions:
            </span>{" "}
            KEY WAY MARKETS LTD a Company limited by shares that is formed and
            registered under the Laws of the Abu Dhabi Global Market (“ADGM”)
            under license number 000003041, authorized and regulated by the
            Financial Services Regulatory Authority (“FSRA”) under reference
            number 190005, having its registered offices at Office 103-132,
            Floor 15, Al Khatem Tower, ADGM Square, Al Maryah Island, Abu Dhabi,
            United Arab Emirates (“the Company”). The Company is authorized to
            provide financial products and services to Retail Clients and to
            persons who meet the qualifying criteria of a Professional Client
            and/or Market Counterparty as defined in the ADGM Conduct of
            Business Module. Partner company Key Way Solutions Ltd provides
            content and operates the business, office address: 1st Floor,
            Hadjikyriakeion Bld. 1121 Prodromou Avenue, Strovolo, Nicosia, 2064,
            Cyprus, with company reg. number HE 388418.
            <br></br>
            <br></br>
            <span className="font-medium text-[#000]">Disclaimer</span>: The
            content of this page is for information purposes only and it is not
            intended as a recommendation or advice. Any indication of past
            performance or emulated past performance included in advertisements
            published by the Company is not a reliable indicator of future
            results. The customer carries the sole responsibility for all the
            businesses or investments that are carried out at{" "}
            <a href="#" className="text-[16px]">
              ae.capex.com
            </a>{" "}
            Key Way Group Ltd is the holding company of Key Way Investments Ltd,
            JME Financial Services (Pty) Ltd and Key Way Markets Ltd. Key Way
            Investments Ltd operates capex.com and is authorized and regulated
            by the Cyprus Securities and Exchange Commission (CySEC) (license
            no.{" "}
            <a href="#" className="text-[16px]">
              292/16
            </a>
            ) with branches in Romania and Spain, and registered with the
            Romanian Financial Supervisory Authority (ASF) (registration no.
            <a href="#" className="text-[16px]">
              PJM01SFIM/400013
            </a>
            ), and with the Spanish National Securities Market Commission (CNMV)
            (registration no.{" "}
            <a href="#" className="text-[16px]">
              4389
            </a>
            ), respectively. JME Financial Services (Pty) Ltd operates
            za.capex.com and is authorized and regulated by the South African
            Financial Sector Conduct Authority (FSCA) (license no.
            <a href="#" className="text-[16px]">
              37166
            </a>
            ). Key Way Markets Ltd operates ae.capex.com and is authorized and
            regulated by the Abu Dhabi Global Market (ADGM) Financial Services
            Regulatory Authority (license no.
            <a href="#" className="text-[16px]">
              190005
            </a>
            ). KW Investments Ltd operates sc.capex.com and is authorized and
            regulated by the Seychelles Financial Services Authority (FSA)
            (license no.{" "}
            <a href="#" className="text-[16px]">
              SD020
            </a>
            ).
          </p>
        </div>
      </div>
    )
}

export default FooterDisclaimer
