import React from 'react'
import MenuItemsMobile from '../header-components/MenuItemsMobile'

const NavMenu = ({menu}) => {
    return (
      <div className="mt-6">
        <nav className="grid">
          {menu.map(menuItem => (
            <MenuItemsMobile data={menuItem} />
          ))}
        </nav>
      </div>
    )
}

export default NavMenu
