import * as React from "react"
import { Popover, Transition } from "@headlessui/react"
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const LangSelector = ({ data }) => {
  const { flag, subLangs } = data

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
            )}
          >
            <div className="flex items-center mr-[1.5em] mt-[6px]">
              <img
                className=" flex items-center h-[1.4rem]"
                src={flag}
                alt=""
              />
              <IoMdArrowDropdown
                className={classNames(
                  open ? "rotate-180 " : "",
                  "ml-2 h-7 w-7  transition delay-0  ease-in-out  "
                )}
                aria-hidden="true"
              />
            </div>
          </Popover.Button>
          <Transition
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute mt-[1.75rem] right-0 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 z-[99] bg-[#fff]">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 ">
                  {subLangs.map((item, index) => (
                    <a
                      key={index}
                      href={item.href}
                      className="-m-3 p-3 flex items-start rounded-lg blackLink z-[99]"
                    >
                      <div className=" flex items-center">
                        <img
                          className=" flex items-center h-[1.4rem] mr-[1em]"
                          src={item.flag}
                          alt=""
                        />
                        <p className="blackLink text-[14px] 2xl:text-[16px]">
                          {item.name}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default LangSelector
