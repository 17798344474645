import * as React from "react"
import { useRef, useState, useEffect } from "react"
import { Popover, Transition } from "@headlessui/react"
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const MenuItems = ({ data, isVisible }) => {
  const { name, subLinks, link, useHover } = data

  const useDetectOutsideClick = (el, initialState) => {
    const [isActive, setIsActive] = useState(initialState)

    useEffect(() => {
      const pageClickEvent = e => {
        const elements = Array.isArray(el) ? el : [el]
        let outside = true

        elements.forEach(element => {
          if (element.current !== null && element.current.contains(e.target)) {
            outside = false
          }
        })

        if (outside) setIsActive(false)
      }

      if (isActive) {
        window.addEventListener("click", pageClickEvent)
      }

      return () => {
        window.removeEventListener("click", pageClickEvent)
      }
    }, [isActive, el])

    return [isActive, setIsActive]
  }

  const dropdownRef = useRef(null)

  const [openDropdown, setOpenDropdown] = useDetectOutsideClick(
    dropdownRef,
    false
  )
  const [mouseOverButton, setMouseOverButton] = useState(false)
  const [mouseOverMenu, setMouseOverMenu] = useState(false)

  const timeoutDuration = 200
  let timeoutButton
  let timeoutMenu

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton)
    {
      useHover && setOpenDropdown(true)
    }
    setMouseOverButton(true)
  }
  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(() => {
      useHover && setMouseOverButton(false)
    }, 0)
  }

  const onMouseEnterMenu = () => {
    clearTimeout(timeoutMenu)
    setMouseOverMenu(true)
  }
  const onMouseLeaveMenu = () => {
    timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
  }

  const show = openDropdown && (mouseOverMenu || mouseOverButton)

  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <div
            onClick={() => (!useHover ? setOpenDropdown(!openDropdown) : null)}
            onMouseEnter={onMouseEnterButton}
            onMouseLeave={onMouseLeaveButton}
            onKeyPress={null}
            role="button"
            tabIndex="0"
          >
            <Popover.Button
              as={link?.enabled ? "a" : "button"}
              href={link?.enabled && link?.href ? link.href : null}
              target={link?.newTab ? "_blank" : ""}
              className={classNames(
                open ? "text-gray-900" : "text-gray-500",
                "group  rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
              )}
            >
              <span
                className={classNames(
                  isVisible ? "whiteOffsetLink" : "whiteLink",
                  "text-[14px] 2xl:text-[18px] font-normal"
                )}
              >
                {name}
              </span>
              <IoMdArrowDropdown
                className={classNames(
                  show ? "rotate-180" : "",
                  isVisible
                    ? "fill-[#fff]"
                    : "fill-[#fff] group-hover:fill-[#000]",
                  "ml-2 mt-2 h-7 w-7  transition delay-0  ease-in-out"
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              show={show}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                ref={dropdownRef}
                onMouseEnter={onMouseEnterMenu}
                onMouseLeave={onMouseLeaveMenu}
                static
                className="absolute mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 z-[99] bg-[#fff] rounded-2xl"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-[#fff] px-5 py-6 sm:gap-8 sm:p-8">
                    {subLinks.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-start"
                      >
                        <div className="ml-4">
                          <p className="blackLink text-[14px] 2xl:text-[16px]">
                            {item.name}
                            {item.subItems
                              ? item.subItems.map(item => (
                                  <span
                                    as="a"
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 p-3 pl-[0] first:pt-[20px] ml-[8px] flex items-start rounded-lg blackLink group text-[16px]"
                                  >
                                    <IoMdArrowDropdown
                                      className="mt-2 h-7 w-7 -rotate-90  transition delay-0  ease-in-out fill-[#000] group-hover:fill-[#0995dd]"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </span>
                                ))
                              : null}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </>
  )
}

export default MenuItems
