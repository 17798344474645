import React from "react"
import { Fragment, useRef } from "react"
import { Popover, Transition, Dialog } from "@headlessui/react"
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const licences = [
  {
    name: "FSCA",
    button: "#",
    subList: [
      {
        text: "za.capex.com, operated by JME Financial Services (Pty) Ltd Regulated by Financial Sector Conduct Authority (FSCA) under the License no. 37166",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:300",
      },
    ],
  },
  {
    name: "CySEC",
    button: "#",
    subList: [
      {
        text: "CAPEX.com, operated by Key Way Investments Limited Regulated by CySEC under the License no. 292/16",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:30 for retail clients",
      },
      {
        text: "ICF Investor Compensation up to EUR 20,000",
      },
    ],
  },
  {
    name: "ADGM (FSRA)",
    button: "#",
    subList: [
      {
        text: "ae.capex.com, operated by Key Way Markets Ltd, Regulated by ADGM Financial Services Regulatory Authority under the License no. 190005",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:30",
      },
      {
        text: "For Retail clients, Professional clients and Market Counterparties",
      },
    ],
  },
  {
    name: "FSA",
    button: "#",
    subList: [
      {
        text: "sc.capex.com, operated by KW Investments Limited, Regulated by the Seychelles Financial Services Authority, under the License no. SD020",
      },
      {
        text: "Client funds are guarded in segregated bank accounts",
      },
      {
        text: "Negative Balance Protection",
      },
      {
        text: "Leverage up to 1:300",
      },
    ],
  },
]

export default function LicenceSelectorMobile({ openMobile, setopenMobile }) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={openMobile} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[999] inset-0 overflow-y-hidden"
        initialFocus={cancelButtonRef}
        onClose={setopenMobile}
      >
        <div className="flex items-center justify-center min-h-full p-0 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300  transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#000] bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-center overflow-hidden overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle fixed h-[96%] md:h-[93%] max-w-[90%] sm:max-w-[60%] md:max-w-[90%] sm:w-full">
              <div className="bg-white pt-0 pb-4 sm:p-0 sm:pb-4">
                <div
                  onClick={() => setopenMobile(false)}
                  className=" flex items-center justify-center absolute right-0 top-[9px] left-auto mr-[1em] w-[25px] h-[25px] bg-[#fff] shadow-outer z-[9] rounded-full"
                >
                  <AiOutlineClose className="h-8 w-8 text-[#000] " />
                </div>
                <div className="text-center">
                  {licences.map(licence => {
                    return (
                      <Popover>
                        {({ open }) => (
                          <div
                            className={classNames(
                              open ? "shadow-inset" : "",
                              "transition-all ease-out duration-700"
                            )}
                          >
                            <Popover.Button className="flex justify-center items-center py-[2em] w-full text-center shadow-bottom">
                              <span className=" text-[21px]">
                                {licence.name}
                              </span>
                            </Popover.Button>
                            <Transition
                              show={open}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="pb-[2em] text-sm text-gray-500 rournded-lg">
                                <div className="transition-all ease-out duration-700">
                                  <div className="">
                                    <ul className="">
                                      {licence.subList.map((item, index) => (
                                        <li
                                          key={index}
                                          className=" py-[1em] px-[1.3em] bg-[#f7f7f7] even:bg-[#f0f0f0]"
                                        >
                                          <p className="text-[greyColor] text-[1.4em]">
                                            {item.text}
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div className="flex justify-center">
                                    <a
                                      className="btnPrimarySmall text-[1.6em] mt-[1em] flex items-center text-center justify-center"
                                      type="submit"
                                      href={licence.button}
                                      onClick={() => setopenMobile(false)}
                                    >
                                      <span>Continue</span>
                                      <IoMdArrowDropdown className="-rotate-90 h-7 w-7 mt-1  transition delay-0 ease-in-out fill-[#fff]" />
                                    </a>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </div>
                        )}
                      </Popover>
                    )
                  })}
                </div>
                <div className="flex justify-center flex-col">
                  <p className="text-[1.4em] text-center w-[90%] my-0 mx-auto mt-[3em]">
                    The information on the website is displayed in accordance
                    with the regulation you have chosen. If you would like to
                    switch to a different regulation, you can select another one
                    from the menu and the website will adjust accordingly.
                  </p>
                  <a
                    href="#"
                    className="blueLink text-[1.4em] text-center w-[90%] my-0 mx-auto mt-[1em] mb-[1em]"
                  >
                    For more information regarding the trading conditions,
                    please click here.
                  </a>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
