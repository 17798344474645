import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Thumbs } from "swiper"
import "swiper/css/bundle"
import "../../../styles/styles.css"
import Progress from "./progress"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const DesktopSlide = () => {
  SwiperCore.use([Autoplay, Thumbs])
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState()
  const [isHovered, setIsHovered] = useState(false)
  const data = useStaticQuery(graphql`
    {
      bigImages: allFile(
        filter: {
          extension: { regex: "" }
          relativeDirectory: { eq: "sliderImages" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                breakpoints: [1020, 1366, 1920]
                placeholder: BLURRED
              )
            }
          }
        }
      }

      thumbs: allFile(
        filter: {
          extension: { regex: "" }
          relativeDirectory: { eq: "sliderImages" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 140
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="relative ondesktop">
        <Swiper
          className="mySwiper2"
          modules={[Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop={true}
          preloadImages={false}
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
        >
          {data.bigImages.edges.map(({ node }) => (
            <div className="relative">
              <SwiperSlide>
                <GatsbyImage
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className=""
                  image={getImage(node)}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="slide"
                />
                <div
                  className="absolute h-full w-full container"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="h-full flex flex-col justify-center z-[99]">
                    <p className="flex flex-col text-center container text-white px-0 text-[28px] font-bold uppercase">
                      Fast Execution &<span>Commission Free Trading</span>
                    </p>
                    <div className="text-center mt-[2em]">
                      <button className="btnPrimarySmall text-[14px]">
                        Start Trading
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>

        <div
          className="absolute bottom-0 left-[50%]  transform -translate-x-2/4 z-[99] w-full"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Swiper
            className="mySwiper"
            modules={[Thumbs]}
            watchSlidesProgress={true}
            onSwiper={setThumbsSwiper}
            spaceBetween={0}
            slidesPerView={5}
            centeredSlides={true}
            centeredSlidesBounds={true}
            allowTouchMove={false}
          >
            {data.thumbs.edges.map(({ node }, index) => (
              <SwiperSlide className="flex flex-col bg-[transparent]">
                <GatsbyImage
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="rounded-lg"
                  height={51}
                  width={141}
                  image={getImage(node)}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="slide"
                />
                <Progress
                  index={index}
                  isHovered={isHovered}
                  activeIndex={activeIndex}
                  done="100"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default DesktopSlide
