import React from 'react'
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const HeaderLogIn = ({isVisible}) => {
    return (
      <div className="group flex items-center">
        <a
          href="#"
          className={classNames(
            isVisible
              ? "whiteOffsetLink"
              : "whiteLink group-hover:text-[#000] opacity-100",
            "whitespace-nowrap group font-normal  mr-[0.5em] "
          )}
        >
          Log in
        </a>
        <IoMdArrowDropdown
          className={classNames(
            isVisible ? "fill-[#fff]" : "fill-[#fff]",
            "-rotate-90 mr-[2em] h-7 w-7 mt-1  transition delay-0 ease-in-out group-hover:text-secondaryColor"
          )}
        />
      </div>
    )
}

export default HeaderLogIn
