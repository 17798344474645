import React, {Fragment, useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import "swiper/css/bundle"
import "../../../styles/styles.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BsPlusCircle } from "@react-icons/all-files/bs/BsPlusCircle"
import { IoIosCloseCircleOutline } from "@react-icons/all-files/io/IoIosCloseCircleOutline"
import { Dialog, Transition } from "@headlessui/react"
import ProgressMobile from "./progressMobile"


function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const MobileSlide = () => {
  const cancelButtonRef = useRef(null)
  SwiperCore.use([Autoplay])
  const [activeIndex, setActiveIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const data = useStaticQuery(graphql`
    {
      bigImages: allFile(
        filter: {
          extension: { regex: "" }
          relativeDirectory: { eq: "mobileSliderImages" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="relative onmobile">
        <div className={classNames(open ? "hidden mt-0" : "block mt-[9em]")}>
          <Swiper
            className="mySwiper2"
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            preloadImages={false}
            spaceBetween={0}
            slidesPerView={1.2}
            onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
          >
            {data.bigImages.edges.map(({ node }) => (
              <div className="relative">
                <SwiperSlide className="pl-[1.5em] relative">
                  <GatsbyImage
                    className=""
                    image={getImage(node)}
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="slide"
                  />
                  <BsPlusCircle
                    className="absolute bottom-10 right-12 h-14 w-14 text-white z-10"
                    onClick={() => setOpen(true)}
                  />
                  <div className="absolute h-full w-full container">
                    <div className="h-full flex flex-col justify-center px-[0.5em]">
                      <p className="flex flex-col text-center container text-white px-0 text-[18px] md:text-[28px] font-bold uppercase">
                        Fast Execution &<span> Commission Free Trading</span>
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
        <div className="absolute mt-0 top-0 h-full w-full">
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-[999] inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <div className="flex items-end justify-center min-h-full text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-[#000] bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Swiper
                    className={classNames(
                      open ? "block fixed h-full" : "hidden",
                      "SwiperStory"
                    )}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    preloadImages={false}
                    spaceBetween={0}
                    slidesPerView={1}
                    initialSlide={activeIndex}
                    onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
                  >
                    {console.log("index: ", activeIndex)}
                    {data.bigImages.edges.map(({ node }, index) => (
                      <div className="relative">
                        <SwiperSlide className="relative h-full">
                          <GatsbyImage
                            className="h-full"
                            image={getImage(node)}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="slide"
                          />
                          <div className="absolute h-full w-full container">
                            <div className="h-full flex flex-col justify-center px-[0.5em]">
                              <p className="flex flex-col text-center container text-white px-0 text-[28px] md:text-[32px] font-bold uppercase">
                                Fast Execution &{" "}
                                <span>Commission Free Trading</span>
                              </p>
                              <div className="text-center mt-[2em]">
                                <button className="btnPrimarySmall text-[14px] md:text-[28px]">
                                  Start Trading
                                </button>
                              </div>
                            </div>
                          </div>
                          <IoIosCloseCircleOutline
                            className="absolute bottom-10 left-1/2  transform -translate-x-2/4 h-16 w-16 text-white"
                            onClick={() => setOpen(false)}
                          />
                          <ProgressMobile
                            className=""
                            index={index}
                            activeIndex={activeIndex}
                            done="100"
                          />
                        </SwiperSlide>
                      </div>
                    ))}
                  </Swiper>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  )
}

export default MobileSlide
