import React from 'react'
import { Popover } from "@headlessui/react"
import MenuItems from '../header-components/MenuItems'

const HeaderMenu = ({menu, isVisible}) => {
    return (
        <Popover.Group as="nav" className="flex space-x-10">
          {menu.map(menuItem => (
            <MenuItems data={menuItem} isVisible={isVisible} />
          ))}
        </Popover.Group>
    )
}

export default HeaderMenu
