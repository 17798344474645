import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const headerLogo = ({ isVisible }) => {
  return (
    <div>
      <a href="#" className="flex">
        <span className="sr-only">CAPEX.com</span>
        {isVisible ? (
          <div className="hidden md:flex items-center">
            <StaticImage
              className="h-full  w-[13rem] transition-all duration-1000 ease-in"
              src="../../../images/CAPEX_red.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Capex.com"
              placeholder="blurred"
            />
          </div>
        ) : (
          <div className="hidden md:flex items-center">
            <StaticImage
              className="h-full  w-[13rem] transition-all duration-1000 ease-in"
              src="../../../images/CAPEX_white.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Capex.com"
              placeholder="blurred"
            />
          </div>
        )}
        <div className="md:hidden">
          <StaticImage
            className="h-full flex items-center w-[13rem]"
            src="../../../images/CAPEX_red.svg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Capex.com"
            placeholder="blurred"
          />
        </div>
      </a>
    </div>
  )
}

export default headerLogo
